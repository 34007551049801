#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
  color: black;
}

.logo-image {
  height: 42px;
  margin-top: 20px;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 20px;
}
